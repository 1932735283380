import React, { useState } from 'react';
import { Button, Form, Modal, Row, Col, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Config from '../data/config.json';

const CookieConsent = (props) => {
  const localCookies = JSON.parse(window.localStorage.getItem('allow-cookies'));
  const [essential, setEssential] = useState(localCookies ? localCookies.essential : true);
  const [statistics, setStatistics] = useState(localCookies ? localCookies.statistics : false);
  const [showModal, setShowModal] = useState(false);
  const [visibilityPopup, setVisibilityPopup] = useState(localCookies ? 'd-none' : 'd-block');
  const openDialog = () => {
    setShowModal(true);
  }
  const closeDialog = () => {
    setShowModal(false);
  }
  const acceptAll = () => {
    setEssential(true);
    setStatistics(true);

    const cookies = {essential: true, statistics: true};

    window.localStorage.setItem('allow-cookies', JSON.stringify(cookies));

    setVisibilityPopup('d-none');
    closeDialog();
    addAnalytics();
  }
  const acceptChoices = () => {
    const cookies = {essential: essential, statistics: statistics};
    window.localStorage.setItem('allow-cookies', JSON.stringify(cookies));
    
    setVisibilityPopup('d-none');
    closeDialog();

    if (statistics) {
      addAnalytics();
    }
  }
  const addAnalytics = () => {
    if (localCookies && !showModal && statistics) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${Config.GTAG}`;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', Config.GTAG);
    }
  }

  addAnalytics();

  return (
    <>
      <div className={`consent ${(localCookies && !props.show) ? visibilityPopup : 'd-block'}`}>
        <p>Deze website gebruikt cookies.<br />U kunt uw cookievoorkeuren aanpassen<br />of alle cookies accepteren.</p>
        <Button variant="light" onClick={openDialog}>Aanpassen</Button>&nbsp;
        <Button variant="primary" onClick={acceptAll}>Alles aanvaarden</Button>
      </div>
      <Modal show={showModal} onHide={closeDialog} backdrop="static" size="lg">
        <Modal.Header closeButton><h4>{Config.SiteName} gebruikt cookies</h4></Modal.Header>
        <Modal.Body>
          <p>
            De volgende cookies kunnen door onze website gebruikt worden.
            Hieronder kunt u aangeven of u deze al dan niet wenst te aanvaarden.
          </p>
          <p>Lees er meer over in <Link to="/cookiebeleid" onClick={closeDialog}>ons cookiebeleid</Link>.</p>
          <Row>
            <Col xs={1}>
              <Form.Switch id="essential" aria-label="Essentieel" checked={essential} disabled />
            </Col>
            <Col className="d-block d-md-none"></Col>
            <Col xs={10}>
              <FormLabel for="essential">
                <strong>Essentiële cookies</strong> <small>(Altijd verplicht)</small>
                <p>Wij gebruiken cookies die technisch noodzakelijk zijn voor een vlot bezoek aan de website. Deze cookies plaatsen wij automatisch zodra u op de website komt en u kunt ze niet weigeren. We zijn wel verplicht om ze voor u op te lijsten.</p>
              </FormLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <Form.Switch id="statistics" aria-label="Statistiek" checked={statistics} onChange={e => setStatistics(e.target.checked)} />
            </Col>
            <Col className="d-block d-md-none"></Col>
            <Col xs={10}>
              <FormLabel for="statistics">
                <strong>Statistische cookies</strong>
                <p>Deze cookies volgen uw bezoek en de herkomst van uw bezoek. We gebruiken ze om statistieken op te stellen waarop we ons baseren om de site te verbeteren en gebruiksvriendelijker te maken. De gegevens worden anoniem geanalyseerd.</p>
              </FormLabel>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={acceptChoices}>
            Mijn keuze bevestigen
          </Button>
          <Button variant="primary" onClick={acceptAll}>
            Alle cookies aanvaarden
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ); 
}

export default CookieConsent;