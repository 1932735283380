import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <Card className="mb-3">
      <Card.Header>Maak een afspraak</Card.Header>
      <Card.Body>
        <Card.Text>
          Op onze digitale agenda kunt u snel en gemakkelijk een afspraak maken.
        </Card.Text>
        <Link to="/afspraak-maken" className="btn btn-primary">Afspraak maken</Link>
      </Card.Body>
    </Card>
  );
}

export default Sidebar;