import React from 'react';
import { Link } from 'react-router-dom';
import Config from '../data/config.json';
import {Phone} from './Links';

const Footer = () => {
  return (
    <footer className="bg-light">
      <p>
        {Config.Address.StreetAddress} in {Config.Address.PostalCode} {Config.Address.City}
        <span className="d-md-none"><br /></span>
        <span className="d-none d-md-inline"> | </span>
        Tel: <Phone number={Config.Phone} />
        <span className="d-md-none"><br /></span>
        <span className="d-none d-md-inline"> | </span>
        E-mail: <a href={`mailto:${Config.Email}`}>{Config.Email}</a>
        <br />
        <small>
          <Link to="/privacybeleid">Privacybeleid</Link> | <Link to="/cookiebeleid">Cookiebeleid</Link>
        </small>
      </p>
      <p>
        <small>
          &copy; <strong>{Config.SiteName}</strong> &ndash;
          Website by <strong><a href="https://pimaxplus.com">pimaxplus.com</a></strong>
        </small>
      </p>
    </footer>
  ); 
}

export default Footer;