import React from 'react';
import { Carousel } from 'react-bootstrap';
import Config from '../data/config.json';

const Jumbotron = () => {
  return (
    <Carousel fade indicators={false} nextLabel="Volgende" prevLabel="Vorige" prevIcon={<span className="prev-icon">{String.fromCharCode(10216)}</span>} nextIcon={<span className="next-icon">{String.fromCharCode(10217)}</span>}>
      {/* Logo */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-logo-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-logo-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-logo-lg.jpg" alt={`Het logo van ${Config.SiteName}`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-logo.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-logo.jpg" type="image/jpeg" />
          <img src="/img/banner-logo.jpg" alt={`Het logo van ${Config.SiteName}`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Gevel */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-gevel-lg-3.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-gevel-lg-3.jpg" type="image/jpeg" />
          <img src="/img/banner-gevel-lg-2.jpg" alt={`De praktijk van ${Config.SiteName}.`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-gevel-3.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-gevel-3.jpg" type="image/jpeg" />
          <img src="/img/banner-gevel-2.jpg" alt={`De praktijk van ${Config.SiteName}.`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Teamfoto */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-team-2024-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-team-2024-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-team-2024-lg.jpg" alt={`Het team van van ${Config.SiteName}`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-team-2024.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-team-2024.jpg" type="image/jpeg" />
          <img src="/img/banner-team-2024.jpg" alt={`Het team van ${Config.SiteName}`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Bo */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-valies-bo-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-valies-bo-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-valies-bo-lg.jpg" alt={`Dokter Bo Debaere en een dokterstas.`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-valies-bo.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-valies-bo.jpg" type="image/jpeg" />
          <img src="/img/banner-valies-bo.jpg" alt={`Dokter Bo Debaere en een dokterstas.`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Nadine */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-nadine-speelhoek-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-nadine-speelhoek-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-nadine-speelhoek-lg.jpg" alt={`Nadine Vercaemer de kinderhoek van ${Config.SiteName}`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-nadine-speelhoek.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-nadine-speelhoek.jpg" type="image/jpeg" />
          <img src="/img/banner-nadine-speelhoek.jpg" alt={`Nadine Vercaemer en de kinderhoek van ${Config.SiteName}`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Teamfoto 2 */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-team-2024-lg-2.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-team-2024-lg-2.jpg" type="image/jpeg" />
          <img src="/img/banner-team-2024-lg-2.jpg" alt={`Het team van van ${Config.SiteName}`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-team-2024-2.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-team-2024-2.jpg" type="image/jpeg" />
          <img src="/img/banner-team-2024-2.jpg" alt={`Het team van ${Config.SiteName}`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Marie-Laure */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-marie-laure-wachtzaal-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-marie-laure-wachtzaal-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-marie-laure-wachtzaal.jpg" alt={`Dokter Marie-Laure Bonte en de wachtzaal.`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-marie-laure-wachtzaal.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-marie-laure-wachtzaal.jpg" type="image/jpeg" />
          <img src="/img/banner-marie-laure-wachtzaal.jpg" alt={`Dokter Marie-Laure Bonte en de wachtzaal.`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
      {/* Jolien */}
      <Carousel.Item>
        <picture className="d-none d-lg-block">
          <source srcSet="/img/webp/banner-smile-jolien-lg.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-smile-jolien-lg.jpg" type="image/jpeg" />
          <img src="/img/banner-smile-jolien.jpg" alt={`Dokter Jolien Decruyenaere en versiering.`} width="2632" height="600" />
        </picture>
        <picture className="d-lg-none">
          <source srcSet="/img/webp/banner-smile-jolien.webp" type="image/webp" />
          <source srcSet="/img/webp/banner-smile-jolien.jpg" type="image/jpeg" />
          <img src="/img/banner-smile-jolien.jpg" alt={`Dokter Jolien Decruyenaere en versiering.`} width="4032" height="1140" />
        </picture>
      </Carousel.Item>
    </Carousel>
  );
}

export default Jumbotron;