import React, { useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap'
import { NavLink, Link, Outlet } from 'react-router-dom';
import logo from '../logo.svg';
import Config from '../data/config.json';

const Menu = () => {
  const [expanded, setExpanded] = useState(false);
  const collapse = () => setTimeout(() => {setExpanded(false)}, 150);

  return (
    <header className="sticky-top">
      <Navbar bg="light" variant="light" collapseOnSelect={true} expand="md" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt={`Logo van ${Config.SiteName}`} width="30" height="30" className="d-inline-block align-top" aria-label={`Logo van ${Config.SiteName}`} /> <span className="d-md-none d-lg-inline">{Config.SiteName}</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ?  false : "expanded")} />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="ml-auto">
              <NavLink to="/" className="nav-link" onClick={collapse}>Start</NavLink>
              <NavLink to="/even-voorstellen" className="nav-link" onClick={collapse}>Even voorstellen</NavLink>
              <NavLink to="/afspraak-maken" className="nav-link" onClick={collapse}>Afspraak maken</NavLink>
              <NavLink to="/nieuws-verlof" className="nav-link" onClick={collapse}>Nieuws / Verlof</NavLink>
              <NavLink to="/nuttige-info" className="nav-link" onClick={collapse}>Nuttige info</NavLink>
              <NavLink to="/contact" className="nav-link" onClick={collapse}>Contact</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>   
      <Outlet />
    </header>
  ); 
}

export default Menu;