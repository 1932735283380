import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Row, Container, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './css/custom.scss';
import Config from './data/config.json';
import Menu from './components/Menu';
import CookieConsent from './components/CookieConsent';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Jumbotron from './components/Jumbotron';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));

const Appointment = lazy(() => import('./pages/Appointment'));
const News = lazy(() => import('./pages/News'));
const Info = lazy(() => import('./pages/Info'));
const Contact = lazy(() => import('./pages/Contact'));

const Privacy = lazy(() => import('./pages/Privacy'));
const Cookies = lazy(() => import('./pages/Cookies'));
const Error = lazy(() => import('./pages/Error'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => { window.scrollTo(0, 0); }, [pathname]);

  return null;
}

export default function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <Helmet htmlAttributes={{lang: 'nl-be'}}>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Config.Logo} />
        <meta property="og:image_alt" content={Config.SiteName} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={Config.Logo} />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Menu />
        <Jumbotron />
        <Container className="my-3 max-space">
          <main>
            <Row>
              <section className="col order-2 order-md-1">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/cookiebeleid" element={<Cookies />} />
                  <Route path="/privacybeleid" element={<Privacy />} />
                  <Route path="/even-voorstellen" element={<About />} />
                  <Route path="/afspraak-maken" element={<Appointment />} />
                  <Route path="/afspraak-maken" element={<Error />} />
                  <Route path="/nieuws-verlof" element={<News />} />
                  <Route path="/nuttige-info" element={<Info />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="*" element={<Error />} status={404} />
                </Routes>
              </section>
              <Routes>
                <Route path="/afspraak-maken" element={<></>} />
                <Route path="*" element={<aside className="col-md-4 col-sm-12 order-1 order-md-2"><Sidebar /></aside>} />
              </Routes>
            </Row>
          </main>
        </Container>
        <Footer />
        <Routes>
          <Route path="/cookiebeleid" element={<CookieConsent show={true} />} />
          <Route path="*" element={<CookieConsent />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}